import React, { useContext, useEffect, useRef } from 'react';
import { AppContext } from '@/store/context';
import { CaseStudyData, CaseStudyProps } from '@/mock/SiteData';
import { LeftRight } from '@/components/shared/left-right';
import { CopyCentered } from '@/components/shared/copy-centered';
import { Separator } from '@/components/ui/separator';
import { TechnologiesList } from '@/components/shared/technologies-list';
import { TitleCopy } from '@/components/shared/title-copy';
import { Review } from '@/components/shared/review';
import { ImageGallery } from '@/components/shared/image-gallery';
import { CaseStudyBanner } from '@/components/shared/case-study-banner';
import { LeftRightCopy } from '@/components/shared/left-right-copy';
import { cn } from '@/lib/utils';
import SEO from '@/components/shared/SearchEngineOptimizations/SearchEngineOptimizations';
import useTriggerLightTheme from '@/hooks/useTriggerLightTheme';
import NotFoundPage from '@/pages/404';
import ReactLogo from '@/assets/images/technologies/react.inline.svg';
import SpringLogo from '@/assets/images/technologies/spring.inline.svg';
import TypescriptLogo from '@/assets/images/technologies/typescript.inline.svg';
import ClutchLogo from '@/assets/images/clutch.inline.svg';
import useScrollAnimation from '@/hooks/useScrollAnimation';
import useStickyScrollAnimation from '@/hooks/useStickyScrollAnimation';
import { SectionHeader } from '@/components/shared/section-header';
import { AnimatedTagCopy } from '@/components/shared/animated-tag-copy';
import { FullScreenSlider } from '@/components/shared/full-screen-slider';
import { useTransitionAnimation } from '@/hooks/useTransitionAnimation';

const ANIMATED_CLASSNAME = 'animated fadeInUp';

const caseStudy: CaseStudyProps | undefined = CaseStudyData.find((m) => m.id === 'pem');

const PEM = () => {
  const { setCurrentPage } = useContext(AppContext);

  const { tlState, defaultAnimationOffset } = useTransitionAnimation();

  const projectWhiteSectionRef = useRef<HTMLDivElement>(null);

  useTriggerLightTheme(
    projectWhiteSectionRef.current,
    defaultAnimationOffset,
    defaultAnimationOffset,
    tlState,
    0.5,
  );

  // TODO: we'll to think a better approach to avoid calling several times the same webhook
  const leftRightRef = useScrollAnimation();

  const copyCenteredRef = useScrollAnimation();

  const technologiesListRef = useScrollAnimation();

  const titleCopyFirst = useScrollAnimation();

  const titleCopySecond = useScrollAnimation();

  const imageGalleryFirst = useScrollAnimation();

  const imageGallerySecond = useScrollAnimation();

  const { elementRef: leftRightCopyFirst, titleRef: leftRightCopyFirstTitle } =
    useStickyScrollAnimation();

  const { elementRef: leftRightCopySecond, titleRef: leftRightCopySecondTitle } =
    useStickyScrollAnimation();

  const { elementRef: leftRightCopyThird, titleRef: leftRightCopyThirdTitle } =
    useStickyScrollAnimation();

  const { elementRef: leftRightCopyFourth, titleRef: leftRightCopyFourthTitle } =
    useStickyScrollAnimation();

  const sectionHeaderRef = useScrollAnimation();

  const fullScreenSliderRef = useScrollAnimation();

  const lightSeparatorFirst = useScrollAnimation();

  const lightSeparatorSecond = useScrollAnimation();

  const lightSeparatorThird = useScrollAnimation();

  const darkSeparatorFirst = useScrollAnimation();

  const darkSeparatorSecond = useScrollAnimation();

  useEffect(() => {
    setCurrentPage('Case Study');
  }, []);

  if (!caseStudy) {
    return <NotFoundPage />;
  }

  const {
    hero: { title, heroDescriptionText, bgImg, projectSection },
    pageDescription,
    externalLink,
  } = caseStudy || {};

  return (
    <main className="page page--case-study">
      <CaseStudyBanner
        pageDescription={pageDescription}
        descriptionText={heroDescriptionText}
        title={title}
        bgImg={bgImg}
        projectSection={projectSection}
        paralaxTranslateY={[20, -20]}
        externalLink={externalLink}
      />
      <div className="case-study-content">
        <div ref={projectWhiteSectionRef} className="project-white-section">
          <div className="flex flex-col gap-20 bg-white py-20 lg:gap-36 lg:py-36">
            <TitleCopy
              ref={titleCopyFirst}
              title="The Client"
              description={`"Por el Mar" is a distinguished non-profit organization in the marine conservation sector, driven by a passionate team of scientists, activists, and policy experts. Their mission is to protect and restore the oceans, crucial for biodiversity and combating climate change. Despite their significant contributions, they faced operational challenges that hindered their efficiency and impact.`}
              className={ANIMATED_CLASSNAME}
            />
            <ImageGallery
              ref={imageGalleryFirst}
              className={cn('px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
              images={[{ src: '/images/case-studies/pem/mock-pem2.webp', alt: 'image1' }]}
            />
            <LeftRight
              ref={leftRightRef}
              title="The Need"
              subtitle="A CUSTOM System"
              description="Por el Mar relied on cumbersome spreadsheets for tracking donations, managing expenses, and generating project reports. This method was not only error-prone but also time-consuming and difficult to maintain, affecting their overall operational efficiency."
              imageSrc="/images/case-studies/pem/need.webp"
              imageSide="right"
              className={ANIMATED_CLASSNAME}
            />
            <Separator
              ref={lightSeparatorFirst}
              className={cn('mx-auto w-[95%] max-w-7xl', ANIMATED_CLASSNAME)}
            />
            <CopyCentered
              ref={copyCenteredRef}
              tag="GOAL"
              copy="PEM aimed to streamline their financial management processes to save time, reduce errors, and improve data accuracy."
              description="They sought a custom solution tailored to their unique needs, enabling them to focus more on their mission rather than administrative tasks."
              className={ANIMATED_CLASSNAME}
            />
            <Separator
              ref={lightSeparatorSecond}
              className={cn('mx-auto w-[95%] max-w-7xl', ANIMATED_CLASSNAME)}
            />
            <TitleCopy
              ref={titleCopySecond}
              title="The Challenge"
              description={`The primary challenge was to bridge the gap between 'Por el Mar's' financial expertise and the right technical solutions. We weren't afraid to roll up our sleeves and help untangle their operational mess, ensuring their focus could remain on saving the oceans.`}
              className={ANIMATED_CLASSNAME}
            />
            <ImageGallery
              ref={imageGallerySecond}
              className={cn('px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
              images={[
                { src: '/images/case-studies/pem/pem-challenge.webp', alt: 'image1' },
                { src: '/images/case-studies/pem/pem-challenge2.webp', alt: 'image2' },
              ]}
            />
            <SectionHeader
              title={'The Process'}
              ref={sectionHeaderRef}
              description="Our collaboration was marked by initial in-depth meetings to understand 'Por el Mar's' processes, followed by the development of low-fidelity wireframes to present initial concepts. After refining these ideas into high-fidelity prototypes and receiving client approval, we proceeded to develop the application."
              className={cn('mx-auto px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
            />
            <LeftRightCopy
              ref={leftRightCopyFirst}
              titleRef={leftRightCopyFirstTitle}
              title="Understanding"
              copy="Our process began with a series of in-depth calls where the 'Por el Mar' team walked us through their existing spreadsheets and workflows. We took the time to dive deep into their current methods, identifying pain points and inefficiencies. To gain clarity and ensure we fully understood the flow of information, we created detailed flow charts that mapped out their processes. This foundational step was crucial in setting the stage for the custom solution we would later develop."
              images={[
                { src: '/images/case-studies/pem/understanding-1.webp', alt: 'understanding1' },
                { src: '/images/case-studies/pem/understanding-2.webp', alt: 'understanding2' },
                { src: '/images/case-studies/pem/understanding-3.webp', alt: 'understanding3' },
              ]}
            />
            <LeftRightCopy
              contentSide="left"
              ref={leftRightCopySecond}
              titleRef={leftRightCopySecondTitle}
              title="LoFi Wireframes"
              copy="With a solid understanding of 'Por el Mar's' processes, we moved on to creating low-fidelity wireframes. The goal here was to visually communicate our vision for the platform to their non-technical team. By presenting these early sketches, we ensured that everyone was aligned and on the same page before advancing to high-fidelity wireframes. This step was crucial in bridging the gap between our technical approach and their operational needs, fostering collaboration and setting a clear direction for the project."
              images={[
                { src: '/images/case-studies/pem/lofi-1.webp', alt: 'lofi1' },
                { src: '/images/case-studies/pem/lo-fi-video.mp4', alt: 'LoFiVideo' },
                { src: '/images/case-studies/pem/lofi-3.webp', alt: 'lofi3' },
              ]}
            />
            <LeftRightCopy
              ref={leftRightCopyThird}
              titleRef={leftRightCopyThirdTitle}
              title="HiFi Wireframes"
              copy="Once the initial concepts were approved, we transitioned from low-fidelity wireframes to high-fidelity designs, bringing the platform's vision to life with greater detail and precision. These high-fidelity wireframes served as the blueprint for the final product, showcasing the look, feel, and functionality of the application. We then developed an interactive prototype, allowing the 'Por el Mar' team to experience the platform firsthand. This hands-on approach was essential for gathering feedback and making any necessary adjustments before moving into full development, ensuring that the final product would meet their expectations and needs."
              images={[
                { src: '/images/case-studies/pem/hifi-1.webp', alt: 'hifi1' },
                { src: '/images/case-studies/pem/hifi-2.webp', alt: 'hifi2' },
                { src: '/images/case-studies/pem/hifi-3.webp', alt: 'hifi3' },
              ]}
            />
            <LeftRightCopy
              contentSide="left"
              ref={leftRightCopyFourth}
              titleRef={leftRightCopyFourthTitle}
              title="Development"
              copy="With the high-fidelity designs and prototype finalized, we moved into the development phase. Leveraging our technical expertise, we began building the custom web application tailored specifically for 'Por el Mar's' needs. Throughout this phase, we maintained close communication with their team, providing regular updates and incorporating feedback to ensure the system aligned perfectly with their requirements. Our agile approach allowed us to stay flexible, addressing any challenges swiftly while keeping the project on track. The end result was a robust, user-friendly platform delivered within the tight three-month deadline, ready to empower 'Por el Mar' in their mission to protect the oceans."
            />
            <Separator
              ref={lightSeparatorThird}
              className={cn('mx-auto max-w-7xl w-[95%] lg:-mb-36', ANIMATED_CLASSNAME)}
            />
          </div>
        </div>

        <div className="animate-transition flex flex-col gap-36 bg-loop-black lg:py-36">
          <TechnologiesList
            ref={technologiesListRef}
            icons={[
              { icon: <ReactLogo key="react" />, id: 'react' },
              { icon: <SpringLogo key="spring" />, id: 'spring' },
              { icon: <TypescriptLogo key="typescript" />, id: 'typescript' },
            ]}
            description="For 'Por el Mar's' project, we chose a tech stack that balances reliability, scalability, and security. Java with Spring Boot offered stability and rapid development for their critical operations, while React, TypeScript, and Vite provided a flexible, component-based front end. This combination allowed us to build a secure, scalable system that grows with the organization and is backed by strong community support, ensuring long-term success."
            className={{
              section: ANIMATED_CLASSNAME,
              text: 'animate-transition-text text-white',
            }}
          />
          <div className="block">
            <AnimatedTagCopy
              tag="the result"
              className={{
                section: cn('mx-auto px-4 md:px-8 xl:px-0'),
                tag: 'animate-transition-text',
              }}
              animatedCopy={{
                id: 'animated-copy-1',
                text: '<w>/<w> The custom build web application automated tedious manual tasks, drastically improving <w>efficiency<w> and <w>accuracy<w>. This allowed their team to focus more on their mission of ocean conservation.',
                animationStart: 'top 40%',
                className: 'mx-auto',
              }}
            />
            <AnimatedTagCopy
              className={{ section: cn('mx-auto px-4 mt-12 md:px-8 xl:px-0') }}
              animatedCopy={{
                id: 'animated-copy-2',
                text: '<w>The<w> <w>impact<w> <w>was<w> <w>immediate<w>: streamlined workflows, faster reporting, and a more empowered team, all enhancing their capacity to protect the oceans.',
                revealDelay: 0.75,
                animationStart: 'top 40%',
                className: 'mx-auto',
              }}
            />
          </div>
          <FullScreenSlider
            ref={fullScreenSliderRef}
            className={cn('mx-auto px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
            tag="the result"
            images={[{ src: '/images/case-studies/pem/full-screen-video.mp4', alt: 'Screen1' }]}
          />
          <Separator
            ref={darkSeparatorFirst}
            className={cn('mx-auto max-w-7xl w-[95%] bg-gray-dark', ANIMATED_CLASSNAME)}
          />
          <Review
            client={{ name: 'Guada Fernandez', role: 'CFO at PEM' }}
            message="”The willingness and follow-up of the Loop3 team were vital in achieving our goal“"
            logo={
              <a
                href="https://clutch.co/profile/loop3#review-301821"
                target="_blank"
                rel="noreferrer"
              >
                <ClutchLogo />
              </a>
            }
          />
          <Separator
            ref={darkSeparatorSecond}
            className={cn('mx-auto max-w-7xl w-[95%] bg-gray-dark', ANIMATED_CLASSNAME)}
          />
        </div>
      </div>
    </main>
  );
};

export default PEM;

export const Head = () => {
  if (caseStudy) {
    return (
      <SEO
        title="Loop3 Studio - Por El Mar"
        description={`${caseStudy.hero.heroDescriptionText}`}
      />
    );
  }
};
